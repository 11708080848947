.contact-button{
     position: fixed;
    top: 45%;
    right: 20px;
    display: grid;
    gap: 40px;
}


@media only screen and (max-width: 800px) {
    
    .contact-button{
        top: 57%;
        right: 5px;
        gap: 15px;
    }
    
}