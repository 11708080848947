body {
  /* background-color: white; */
}
.homePage {
  /* padding: 20px 40px; */
  /* background: linear-gradient(to bottom right, #ffd6a8, #ff839b); */
  /* background-color: #e90101; */
}

.homePage-main {
  /* background: linear-gradient(to bottom right, #ffdcb9, #ff7b7b); */
  /* border-radius: 60px; */
  /* padding: 10px 100px; */
}
.homePage-content {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* border: solid 2px #db6232; */
  background-color: white;
  
  /* border-radius: 40px; */
}

.header-small-diaplay {
  display: none;
}

/* ---------------- popular section --------------- */

.home-popular {
  padding: 30px 0 30px;
}

.h-popular-heading {
  text-align: center;
  font-family: poppins;
  font-size: 2rem;
  font-weight: 700;
}

.h-popular-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  padding: 30px 50px;
  text-align: center;
  font-family: poppins;
  font-weight: 600;
  font-size: 1.2rem;
}

.h-popular-c-c img {
  width: 100%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.479) 0px 3px 8px;
}

/* --------- book slot ---------- */

.grooming-service{
  /* margin: 0px 0 0 0; */
}

.grooming-service-heading {
  font-size: 2rem;
  font-weight: 1000;
  font-family: "Playpen Sans";
  color: red;
  color: #ce0000;
  text-align: center;
}

.grooming-service-content{
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  width: 85%;
  padding-top: 30px;
  
}

.grooming-service-content-col{
  /* border: solid; */
  padding: 10px;
  border-radius: 10px;
  text-align: center;
    background: linear-gradient(to bottom right, #ffffff, #ffffff);
    /* background: linear-gradient(to bottom right, #ffdcb9, #ff7b7b); */
    box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;

}

.grooming-service-content-col-img img{
  width: 120px;
  border-radius: 20px;
  border-radius: 50%;
  box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;

}

.grooming-service-content-col-text{
  font-weight: 600;
  
}



/* ------------------------------- */


.slot-book {
  width: 90%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 80px;
}

.slot-book-border-img {
  display: flex;
  align-items: center;
}
.slot-book-border-img img {
  width: 300px;
  margin-left: 120px;
  margin-bottom: -22px;
}

.slot-book-end-img img {
  width: 450px;
  /* margin-right: 40px; */
  margin-bottom: -95px;
}

.slot-book-border {
  box-shadow: rgba(0, 0, 0, 0.2) 0px -2px 10px 0px;
  background: linear-gradient(to bottom right, #ffdcb9, #ff7b7b);
  border-radius: 50px;
  margin: 0 50px 30px;
}

.slot-book-heading {
  text-align: center;
  width: 60%;
  font-size: 2.5rem;
  font-weight: 900;
  font-family: "Playpen Sans";
  color: #ce0000;
}

.slot-book-form-col {
  display: flex;
  padding: 45px 0px 30px 40px;
  align-items: center;
  gap: 40px;
}

.slot-book-form-col form {
  width: 100%;
  display: grid;
  gap: 20px;
}

.slot-book-form-col-1 {
  display: flex;
  width: 100%;
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  align-items: center;
}

.slot-book-form-col-heading {
  font-size: 1.1rem;
  font-weight: 600;
}

.slot-book-form-col-form {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.slot-book-form-col-2 {
  display: flex;
  width: 100%;
  background-color: white;
  padding: 10px 30px;
  border-radius: 10px;
  align-items: center;
}

.slot-book-form-col-2-date {
  display: flex;
  font-size: 1.1rem;
  font-weight: 600;
  gap: 20px;
  width: 80%;
  align-items: center;
}
.slot-book-form-col-2-date input {
  border: 1px solid gray;
  border-radius: 15px;
  padding: 10px;
  width: 70%;
}

.slot-book-form-col-2-time {
  display: flex;
  width: 100%;
  font-size: 1.1rem;
  gap: 20px;
  font-weight: 600;
  align-items: center;
}

.slot-book-form-col-3 {
  /* display: flex; */
  display: flex;
  width: 100%;
  background-color: white;
  padding: 10px 30px;
  border-radius: 10px;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.slot-book-form-col-3 > div {
  align-items: center;
  display: flex;
  gap: 50px;
}

.slot-book-form-col form button {
  background-color: #ce0000;
  color: white;
  border: none;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 10px;
}

.slot-book-form-col form select {
  border: solid 1px rgb(194, 194, 194);
  padding: 10px;
  text-align: center;
  border-radius: 15px;
}

.slot-book-form-col form .slot-book-heading {
  display: none;
}

/* -------------- home-shop ----------------- */

.home-shop {
  margin: 70px 0 50px;
}

.home-shop-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-decoration: underline;
  font-weight: 900;
  color: #ce0000;
  font-family: "playpen sans";
  text-align: center;
}

.home-shop-col {
  display: grid;
  width: 80%;
  margin: auto;
  grid-template-columns: 1fr 2fr 1fr;
  margin: auto;
  justify-content: space-evenly;
}

.home-shop-c-col img {
  width: 320px;
}

.home-shop-c-col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.home-shop-c-col-2 img {
  width: 180px;
}

.home-shop-c-col-2 div {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-shop-small {
  /* display: none; */
}

.home-shop-small {
  display: none;
}

/* -------- deals ---------- */

.deal {
  background-color: #e6ddce;
}
.deals {
  width: 90%;
  margin: auto;
  padding-top: 40px;
}

.home-headings {
  font-size: 2.4rem;
  font-weight: 900;
  font-family: playpen sans;
  color: #ce0000;
  text-align: center;
}

.deals img {
  width: 100%;
}

/* ---------- best selling --------- */

.best-selling {
  margin: 0px 0;
}
.best-selling img {
  width: 250px;
  /* width: 10%; */
}
.best-selling-imgs {
  padding-left: 0px;
  display: flex;
  /* overflow-x: hidden; */
  overflow: auto;
  gap: 40px;
}
.best-selling-imgs::-webkit-scrollbar {
  display: none;
}

.best-img {
  margin-left: 30px;
}
.deal-img-mon {
  display: none;
}

.home-shop-c-col-2 {
  padding: 20px 40px;
}

/* ----------------- why us -----------------  */

.home-why-us{
  padding: 0 0 30px 0;
}


@media only screen and (max-width: 1000px) {
  body {
    background-color: #ffffff;
  }

  .homePage-main {
    padding: 5px 10px;
  }

  .homePage-content {
    border-radius: 20px;
  }

  /* -------------------- */

  .homePage-main {
    padding: 0px;
  }
  .homePage-content {
    border-radius: 0px;
  }

  .homePage-content {
    background: transparent;
    box-shadow: none;
  }

  .homePage-main {
    /* background: linear-gradient(to bottom right, #fbecec, #ffeaea); */
    /* background: linear-gradient(to bottom right, #ffdcb9, #ff6868); */

    /* background: transparent; */
  }
  .header-ld-offer-slider {
    display: none;
  }

  .header-small-diaplay {
    display: block;
  }

  /* ---------------- popular section --------------- */

  .home-popular {
    padding: 15px 0;
  }

  .h-popular-heading {
    font-size: 1.4rem;
  }

  .h-popular-c-c img {
    width: 100px;
    border-radius: 20px;
  }

  .h-popular-content {
    overflow: auto;
    font-size: 0.9rem;
    gap: 20px;
    padding: 20px 20px;
  }

  .h-popular-c-c img {
    width: 150px;
    border-radius: 20px;
  }

  /* ----------- book slot ------------ */

  .grooming-service-heading{
    font-size: 1.5rem;
    width: 80%;
    margin: auto;
  }
  
  .grooming-service-content{
    overflow: auto;
    width: 100%;
    padding: 10px 30px;
    justify-content: start;
    gap: 25px;
  }
  
  
.grooming-service-content-col-img img{
  width: 70px;
  margin: 0 5px;
}


.grooming-service-content-col-text{
  font-weight: 600;
  font-size: .8rem;
  
}
  
  
  /* --------------- */
  
  .slot-book {
    width: 95%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .slot-book-border {
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.518) 0px 2px 8px 0px;
  }

  .slot-book > div > .slot-book-heading {
    display: none;
  }

  .slot-book-border-img img {
    width: 45%;
    /* margin-left: 10px; */
    margin: auto;
    margin-bottom: -25px;
  }
  .slot-book-end-img img {
    display: none;
  }

  .slot-book-border {
    border-radius: 20px;
  }

  .slot-book-form-col {
    display: grid;
    padding: 20px 15px;
    align-items: center;
  }

  .slot-book-form-col-1 {
    padding: 10px 20px;
  }

  .slot-book-form-col-2 {
    display: grid;
    padding: 10px 20px;
    gap: 15px;
  }

  .slot-book-form-col-3 {
    padding: 10px 20px;
  }

  .slot-book-form-col-3 > div {
    gap: 15px;
  }

  .slot-book-form-col-heading {
    font-size: 1rem;
  }

  .slot-book-form-col-3 > div {
    /* font-size: 1rem; */
    display: grid;
    gap: 5px;
  }

  .slot-book-form-col form {
    gap: 10px;
    /* font-size: .8rem; */
  }

  .slot-book-form-col form .slot-book-heading {
    display: block;
    width: 80%;
    margin: auto;
    font-size: 1.5rem;
  }

  .slot-book-end-img {
    display: none;
  }

  .slot-book-form-col-2-date {
    display: grid;
    gap: 5px;
  }

  .slot-book-form-col-2-time {
    display: grid;
    gap: 5px;
  }

  .slot-book-form-col-2-date input {
    width: 220px;
    margin-left: 10px;
  }

  .slot-book-form-col form select {
    width: 220px;
    margin-left: 10px;
  }
  .slot-book-form-col form button {
    font-size: 1.1rem;
  }

  /* ------------ home shop ------------- */

  .home-shop {
    display: none;
  }

  .home-shop-small {
    display: block;
  }

  .home-shop-c-col-small img {
    width: 100%;
  }

  .home-shop-heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  .home-shop-c-col-2 img {
    width: 100px;
  }

  .home-shop-c-col-2 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: start;

    overflow: auto;
    gap: 10px;
    padding: 20px 20px;
    /* align-items: center;
  justify-content: center; */
  }

  /* ---------- deals --------- */

  .home-headings {
    font-size: 1.5rem;
    font-family: 'poppins';
    font-weight: 600;
    text-align: start;
    padding:  0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .home-headings a{
    font-size: .9rem;
    color: #ce0000b7;

  }

  .deal-img-web {
    display: none;
  }

  .deals {
    padding-left: 20px;
    padding-top: 10px;
    width: 100%;
    /* display: none; */
  }

  .deal-img-mon {
    display: block;
  }

  /* ---------- best selling --------- */

  .best-selling {
    gap: 0px 0;
  }

  .best-selling img {
    width: 40%;
    /* filter: drop-shadow(0 0 0.75rem crimson); */
  }

  .best-img {
    margin-left: 10px;
  }

  .best-selling-imgs {
    gap: 0px;
  }

  .best-selling {
    margin-top: 30px;
    padding-bottom: 30px;
  }

  
  .home-shop-small {
    margin: 30px 0;
  }
}
