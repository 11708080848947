.why-us{
    font-family: 'poppins';
    background: rgb(235, 235, 235);
    padding: 40px 0 0 0 ;
}

.why-us-head-heading{
    font-size: 2.2rem;
    text-align: center;
    font-weight: 700;
}
.why-us-head-heading span{
    color: #fe0000;
}
.why-us-head-text{
    text-align: center;
    font-size: 1.1rem;
    width: 790px;
    margin: auto;
    color: #fe0000;
}

.why-us-content{
    margin-top: 20px;
}

.why-us-content-img{
    display: none;
}

.why-us-content-cols{
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    width: 80%;
    margin: auto;
}

.why-us-content-col-1{
    text-align: end;
    margin: 50px 0 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.why-us-content-col-2{
    display: flex;
}

.why-us-content-col-2 img{
    width: 450px;
    padding: 0 50px;
}



.why-us-content-col-3{
    text-align: start;
    margin: 50px 0 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.why-us-content-col-text-heading{
    font-size: 1.3rem;
    font-weight: 600;
}



@media only screen and (max-width: 1000px) {

    .why-us{
        padding: 30px 0 0 0 ;
    }
    
    
    .why-us-head-heading{
        font-size: 1.5rem;
    }
    
.why-us-head-text{
    width: 90%;
    font-size: .9rem;
}
.why-us-content{
    display: flex;
    margin: 0;
    align-items: center;
    padding: 0 0 0 20px ;
    justify-content: space-evenly !important;
}
    
.why-us-content-img{
    display: flex;
}
.why-us-content-img img{
    width: 150px;
}


.why-us-content-cols{
    display: grid;
    grid-template-columns: 1fr ;
    width: 100%;
    /* padding: 0 20px; */
    padding: 10px 0 10px 20px;
    /* margin: auto; */
    /* gap: 10px; */
}

.why-us-content-col-1{
    margin: 0;
    text-align: start;
    gap: 5px;
    margin-bottom: 5px;
}

.why-us-content-col-1>:nth-child(even){
    color: #fe0000;
}

.why-us-content-col-3>:nth-child(odd){
    color: #fe0000;
}

.why-us-content-col-2{
    display: none;
}

.why-us-content-col-3{
    margin: 0;
    text-align: start;
    gap: 5px;
}


.why-us-content-col-text-heading{
    font-size: 1rem;
    font-weight: 600;
}

.why-us-content-col-text-txt{
    font-size: .9rem;
    display: none;
}



    
}



@media only screen and (max-width: 380px) {

    .why-us-content{
        padding: 0 0 0 10px;
    }

    .why-us-content-cols{
        padding: 10px 0 10px 10px;
    }
}


