.landing-page {
  /* height: 100vh; */

  /* background-color: rgb(233, 233, 233) ; */
  /* font-family: playpen sans; */
  font-family: "poppins";
}

.landing-head-top {
  background-color: black;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  /* padding-left: 400px; */

}
.landing-head-top-heading-1 {
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
  animation: blink 4s linear infinite;
}
.landing-head-top-heading-2 {
  color: #fe0000;
  font-weight: 500;
  font-size: 0.9rem;
}

.landing-header {
  /* background-image: url(../../media/images/landing-header\ -\ Copy.jpg); */
  background-image: url(../../media/images/Dog-Grooming-Price-List.jpg);
  background-position: center;
  background-size: cover;
  height: auto;
}

/* ------------------ navbar ------------------------ */

.landing-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 100px; */
  /* background-color: white; */
}

.landing-nav-logo {
  color: rgb(0, 0, 0);
  font-family: playpen sans;
  font-weight: 800;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  text-shadow: rgb(255, 255, 255) 1px 0 4px;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  padding: 10px 100px;
  border-radius: 0 50px 50px 0;
  /* margin: 10px 0; */
  /* margin-top: -20px; */
  /* margin-bottom: 10px; */
}

.landing-nav-logo span {
  text-shadow: rgba(255, 255, 255, 0.27) 1px 2px 4px;
  color: #ce0000;
}

.landing-nav-logo img {
  width: 4rem;
  width: 20rem;
  /* filter: drop-shadow(1px 1px 1px rgb(255, 209, 209)); */
}

.landing-nav-button a {
  color: white;
  text-decoration: none;
  padding: 8px 30px;
  font-weight: 600;
  font-size: 1.2rem;
  background-color: #ce0000;
  /* background-color: red; */
  border-radius: 10px;
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .5), 
    inset -4px -4px 6px 0px rgba(255,255,255,.2),
    inset 4px 4px 6px 2px rgba(0, 0, 0, .4);
}
.landing-nav-button :hover {
  /* background-color: #740909; */
  /* color: #ffb4b4; */
  /* transition: 1s; */
}

/* --------------------------- header content ---------------------- */

.landing-header-content-landing {
  display: grid;
  /* grid-template-columns: 3fr 5fr; */
  grid-template-columns: 5fr 3fr;
  /* padding: 10px 0 0 0; */
  /* padding-top: 10px; */
  /* padding-bottom: -20px; */
}

.landing-header-content {
  text-align: center;
  display: grid;
  gap: 10px;
  border: solid white;
  background-color: #ffffff79;
  padding: 10px 10px;
  /* border-radius: 130px 0 0 130px; */
  border-radius: 0 130px 130px 0;
}

.landing-header-content-heading-1 {
  font-family: "playpen sans";

  font-size: 2.5rem;
  font-weight: 1000;
  text-shadow: rgb(255, 255, 255) 1px 0 4px;
}
.landing-header-content-heading-1>span>span {
  color: #fe0000;
}
.landing-header-content-heading-2 {
  font-size: 1.8rem;
  font-weight: 500;
  text-shadow: rgb(255, 255, 255) 1px 0 4px;
}

.landing-header-content-buttons {
  display: grid;
}
.landing-header-content-button {
  padding: 15px;
}
.landing-header-content-button div {
  animation: wiggle 2s linear infinite;
}
.landing-header-content-button a {
  background-color: #ce0000;
  padding: 10px 40px;
  border-radius: 20px;
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .5), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
  /* border: 2px solid white; */

  /* animation: wiggle 2s linear infinite; */
}
@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-8deg);
  }
  20% {
    transform: rotateZ(5deg);
  }
  25% {
    transform: rotateZ(-5deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}

.landing-header-content-category-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-shadow: rgb(255, 255, 255) 1px 0 4px;
}

.landing-header-content-category-links {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.landing-header-content-category-link img {
  height: 100px;
  margin-bottom: -6px;
  padding: 0;
}

.landing-header-content-category-link {
  width: 130px;
  background-color: rgb(216, 48, 36);
  background: linear-gradient(to bottom right, #ffdcb9, #ff6868);
  background: rgb(243, 243, 243);
  text-decoration: none;
  padding: 10px 15px 0;
  color: black;
  font-weight: 600;
  /* border: 2px solid rgb(113, 113, 113);
  border: 3px solid rgb(255, 255, 255); */
  border-radius: 15px;
  box-shadow:  4px 4px 6px 0 rgba(190, 190, 190, 0.436),
  -4px -4px 6px 0 rgba(111, 117, 124, 0.412), 
inset -4px -4px 6px 0 rgba(255,255,255,.2),
inset 6px 5px 7px 3px rgba(0, 0, 0, 0.36);
}

.landing-header > svg {
  fill: rgb(233, 233, 233);
  fill: white;
  margin-bottom: -10px;
}

/* ------------------ numbers ------------------- */

.landing-header-numbers {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
}
.landing-header-numbers-col {
  text-align: center;
}
.landing-header-numbers-col-inc {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(104, 49, 49);
}
.landing-header-numbers-col-inc > span {
  color: rgb(255, 0, 0);
  font-size: 2.5rem;
}
.landing-header-numbers-col-txt {
  font-size: 1.2rem;
  font-weight: 400;
  color: rgb(50, 50, 50);
}

.grooming-service {
  margin: 40px 0;
}

/* ---------------- packages --------------- */

.landing-package-pet {
  margin: 70px 0;
}

.landing-package-heading-1 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
.landing-package-heading-1 span {
  color: #fe0000;
}

.landing-package-heading-2 {
  color: #fe0000;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.landing-package-cols {
  text-align: center;
  display: flex;
  /* gap: 30px; */
  justify-content: space-evenly;
  justify-content: flex-start;
  margin: 10px 0;
  gap: 30px;
  padding: 0 50px;
  /* height: 560px; */
  /* overflow-x: scroll;
  overflow-y: hidden; */
  overflow: auto;
  /* flex-wrap: wrap;   */
}

.landing-package-cols::-webkit-scrollbar {
  display: none;
}

.landing-package-cols-1 {
  /* border: 5px ridge red; */
  box-shadow: #fe000047 0px 2px 8px 0px;
  box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;
background-color: white;
  border-radius: 30px;
  padding: 30px 20px;
  min-width: 400px;
  /* overflow-y: scroll; */
  margin: 20px 0;
  transition: height 2s;
}

.landing-package-cols-1::-webkit-scrollbar {
  display: none;
}

.landing-package-cols-1-heading-1 span {
  padding: 0 5px;
  font-size: 1.4rem;
  background: #fe0000;
  background: linear-gradient(111.3deg, #f89b29 29.6%, #fe0000 93.6%);
  color: white;
  font-weight: 600;
  border-radius: 5px;
}

.landing-package-cols-1-heading-2 {
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
  margin: 10px 50px;
}

.landing-package-cols-1-heading-2::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 4px;
  border-radius: 2px;
  margin: 10px 0;
  background: linear-gradient(111.3deg, #ff1b6b 29.6%, #f89b29 93.6%);
}

.landing-package-cols-1-heading-2>span {
  /* text-decoration: line-through; */
  color: #444444ce;
}
.landing-package-cols-1-heading-2>span>span{
  color: #fe0000;
  font-weight: 700;
  font-size: 1.7rem;
}

.landing-package-cols-1-points {
  margin: 40px 30px 20px;
}

.landing-package-cols-1-points button {
  color: #ffffff;
  background: linear-gradient(111.3deg, #ff1b6b 29.6%, #f89b29 93.6%);
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  font-size: 1rem;
  margin-top: 10px;
  font-weight: 600;
}

.landing-package-cols-1-single-point {
  display: flex;
  gap: 10px;
  font-size: 1.1rem;
  /* font-weight: 500; */
}
.landing-package-cols-1-single-point > div > i {
  color: #fe0000;
}

.landing-package-cols-1-heading-3 {
  letter-spacing: 5px;
  font-weight: 600;
  font-size: 1.2rem;
  /* margin-bottom: 20px; */
  text-decoration: underline dashed;
  color: #fe0000;
}

.landing-package-cols-1-size {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.landing-package-cols-1-size-medium {
  border-left: 1px solid;
  border-right: 1px solid;
  padding: 0 10px;
}

.landing-package-cols-1-size-text {
  /* display: flex; */
  /* flex-wrap: wrap; */
  width: 100px;
  font-size: 0.8rem;
  /* align-items: center; */
  color: #fe0000;
  font-weight: 500;
}

.landing-package-cols-1-size-small i {
  font-size: 1rem;
}

.landing-package-cols-1-size-medium i {
  font-size: 1.5rem;
}
.landing-package-cols-1-size-large i {
  font-size: 2rem;
}

.landing-package-cols-1-size-rupee {
  font-size: 1.1rem;
  font-weight: 700;
}
.landing-package-cols-1-size-rupee i {
  font-size: 1.1rem;
}

.landing-package-cols-1-button {
  padding: 30px 0 0px 0;
}
.landing-package-cols-1-button a {
  text-decoration: none;
  border-radius: 20px;
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  background-color: #fe0000;
  background: linear-gradient(111.3deg, #fe0000 29.6%, #f89b29 93.6%);
  padding: 8px 30px;
}

.landing-package-cols-1-add-on {
  border: solid red;
  border-radius: 18px;
  /* margin: 20px 0px; */
  margin-top: 20px;
}
.landing-package-cols-1-add-on-heading {
  background: linear-gradient(111.3deg, #fe0000 29.6%, #f89b29 93.6%);
  border-radius: 22px;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  /* border: solid transparent 1px; */
  border: transparent;
  width: 100%;
}

.landing-package-cols-1-add-on-point {
  display: flex;
  gap: 10px;
  padding: 5px 20px;
  text-align: start;
}
.landing-package-cols-1-add-on-point i {
  color: #fe0000;
}

.landing-package-testimonial {
  margin: 40px 0;
}


/* ------------------ boarding ----------------- */
.landing-boarding-cols{
  /* height: 500px; */
  padding: 30px 50px;
  margin: 30px 150px;
  border-radius: 50px;
  box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;
}

.landing-boarding-cols{
  display: grid;
  grid-template-columns: 5fr 4fr;
  
  align-items: center;
}

.landing-boarding-col-1 img{
  width: 80%;
  /* text-align: center; */
  margin: auto;
  /* padding: 0 0 0 40px; */
}

.landing-boarding-col-2-heading{
  font-size: 1.4rem;
  color: #fe0000;
  font-weight: 600;
  margin-bottom: 20px;
}


.landing-boarding-col-2-points{
  display: grid;
  gap: 10px;
}
.landing-boarding-col-2-point{
  font-size: 1.1rem;
  
}

.landing-boarding-col-2-point>i{
  color: #fe0000;
}

/* ------------------ footer ----------------- */

.landing-footer {
  /* ------------------- bg img ------------------  */
  background: url(../../media/images/footer-bg1\ -\ Copy.jpeg);
  background-position: center;
  background-size: cover;
  background-color: black;
  color: white;
}

.landing-footer svg {
  transform: rotate(180deg);
  fill: white;
  margin-top: -2px;
}

.landing-footer-cols {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 0px 150px 40px 100px;
  gap: 50px;
}

.landing-footer-col-1 {
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  /* justify-content: space-evenly; */
  align-items: center;
  /* border-right: solid gray; */
  padding-right: 50px;
}

.landing-footer-col-1-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-family: playpen sans;
  font-weight: 800;
  color: #ce0000;
  gap: 5px;
  text-decoration: none;
}
.landing-footer-col-1-logo img{
  width: 300px;
  background-color: white;
padding: 5px 20px;
border-radius: 5px;
}
.landing-footer-col-1-logo span {
  color: rgb(255, 255, 255);
}

.landing-footer-logo-icon img {
  width: 6rem;
  display: flex;
  align-items: center;
}

.landing-footer-logo-text-2 {
  font-family: poppins;
  font-size: 1.1rem;
  color: white;
  font-weight: 400;
}

.landing-footer-col-1-footer-icon {
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: space-between;
  width: 90%;
  gap: 20px;
}

.landing-footer-col-1-footer-icon > a > i {
  color: white;
  color: rgb(162, 162, 162);
  border: solid;
  border-radius: 50%;
  padding: 10px;
  font-size: 1.2rem;
}

.landing-footer-col-2 {
  display: flex;
  justify-content: space-around;
}

.landing-footer-col-2-col-heading {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 14px;
  position: relative;
}

.landing-footer-col-2-col-heading:before {
  content: "";
  position: absolute;
  bottom: 0;
  display: inline-block;
  width: 50px;
  height: 3px;
  background-color: white;
}

.landing-footer-col-2-col-points {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
  color: rgb(195, 195, 195);
}

.landing-footer-col-2-col-points > div,
.landing-footer-col-2-col-points > a {
  display: flex;
  gap: 15px;
  align-items: center;
  color: rgb(195, 195, 195);
  text-decoration: none;
}
.landing-footer-col-2-col-points > a:hover{
  text-decoration: underline;

}

.landing-footer-cc {
  text-align: center;
  padding: 20px;
  color: rgb(182, 182, 182);
  /* margin-top: 40px; */
  border-top: solid 1px gray;
}
.header-small{
  display: none;
}

/* -------------------------------------------------- */

.header-numbers {
  /* background-color: rgba(187, 187, 187, 0.156); */
  padding: 40px 0;
}
.header-numbers>div {
  margin: auto;
  max-width: 1170px;
  
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.header-numbers-col {
  text-align: center;
}
.header-numbers-col-inc {
  font-size: 2rem;
  font-weight: 700;
  /* color: rgb(104, 49, 49); */
}
.header-numbers-col-inc > span {
  color: rgb(255, 0, 0);
  font-size: 2.5rem;
}
.header-numbers-col-txt {
  font-size: 1.2rem;
  font-weight: 400;
  color: rgb(50, 50, 50);
}

@media only screen and (max-width: 1300px) {
  .landing-package-cols-1 {
  padding: 20px 10px;
  min-width: 380px;

  }
}
@media only screen and (max-width: 800px) {
  .landing-head-top {
    display: grid;
    text-align: center;
  }
  .landing-head-top-heading-1 {
    font-size: 1.1rem;
  }
  .landing-head-top-heading-2 {
    font-size: 0.8rem;
  }

  .landing-header {
    /* background: none; */
    /* background-image: url(../../media/images/blog-4-1.jpg); */
    /* background-image: url(../../media/images/m2_768x940.webp); */
    /* background-image: url(../../media/images/img001\ \(1\).jpeg); */
    /* background-image: url(../../media/images/bg-1\ -\ Copy.jpeg); */
    background-image: url(../../media/images/img001-5.jpeg);
    background-image: none;
    background-position: center;
    background-size: cover;
    height: auto;
  }
  .landing-header>div{
    background: rgba(110, 110, 110, 0.333);
  }

  .landing-nav {
    padding: 8px 10px;
    background-color: #ffffffed;
    border-bottom: 3px solid white;
    /* padding: 5px; */
  }

  .landing-nav-logo img {
    width: 11.5rem;
  }

  .landing-nav-logo {
    padding: 0;
    font-size: 1.3rem;
    background-color: transparent;
  }

  .landing-nav-button a {
    padding: 5px 15px;
    font-size: 0.9rem;
  }

  /* --------------------------- header content ---------------------- */

  .landing-header-content-landing {
    grid-template-columns: 1fr;
    display: none;
  }

  .landing-header-content {
    border: none;
    background-color: transparent;
    padding: 0;
    gap: 20px;
    padding: 20px 0;
  }

  .landing-header-content-heading-1 {
    font-size: 1.8rem;
    color: white;
    text-shadow: rgb(0, 0, 0) 1px 0 4px;
  }

  .landing-header-content-heading-1>span>span {
    /* color: #000000; */
    text-shadow: rgb(255, 255, 255) 1px 0 4px;
    background-color: rgba(255, 255, 255, 0.618);
    border-radius: 10px;
  }

  .landing-header-content-heading-2 {
    font-size: 1.1rem;
    color: white;
    text-shadow: rgb(0, 0, 0) 1px 0 4px;
  }

  .landing-header-content-button {
    padding: 15px;
  }
  .landing-header-content-button a {
    /* background-color: #ff00009e; */
    /* border: 2px solid white; */
    padding: 7px 20px;
    /* border-radius: 5px; */
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 600;
    box-shadow:  4px 4px 6px 0 rgba(153, 152, 152, 0.319),
              -4px -4px 6px 0 rgba(62, 64, 67, 0.301), 
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.327),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.556);
    /* border: 2px solid white; */
  }

  .landing-header-content-category-heading {
    font-size: 1.3rem;
    color: white;
    text-shadow: rgb(0, 0, 0) 1px 0 4px;
  }

  .landing-header-content-category-link img {
    height: 100px;
  }
  .landing-header-content-category-link {
    /* font-size: 0.9rem; */
    font-weight: 700;
    padding: 5px 10px 0;
  }

  /* --------------------------- number ---------------------- */

  .landing-header-numbers {
    /* display: none; */
    flex-wrap: wrap;
    gap: 20px 30px;
    margin-bottom: 30px;
  }

  .landing-header-numbers-col-inc {
    font-size: 1.8rem;
  }

  .landing-header-numbers-col-inc span {
    font-size: 2rem;
  }

  .landing-header-numbers-col-txt {
    font-size: 0.9rem;
  }

  .grooming-service {
    margin: 50px 0;
  }

  .landing-package-pet {
    margin: 50px 0;
  }

  .landing-package {
    margin: 20px 0;
  }
  .landing-package-cols {
    text-align: center;
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    margin: 0px 0;
    padding: 10px 20px;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: auto;
    /* height: 480px; */
  }

  .landing-package-cols-1-heading-1 span {
    font-size: 1.2rem;
  }

  .landing-package-cols-1 {
    margin: 0;
    padding: 20px 0px;
    min-width: 320px;
  }

  .landing-package-cols-1-points {
    margin: 30px 20px 10px;
  }

  .landing-package-cols-1-single-point {
    font-size: 1rem;
  }

  .landing-package-cols-1-size {
    gap: 5px;
  }
  .landing-package-cols-1-size-medium {
    padding: 0 5px;
  }

  .landing-package-cols-1-heading-3 {
    letter-spacing: 4px;
    font-weight: 600;
    font-size: 1.1rem;
    margin: 0px 0;
  }

  .landing-package-cols-1-button {
    padding-top: 20px;
  }

  .landing-package-cols-1-add-on {
    /* border: solid rgb(41, 31, 31); */
    border-radius: 18px;
    /* margin: 20px 0px; */
    margin: 20px 10px 0;
  }
  .landing-package-cols-1-add-on-heading {
    font-size: 1rem;
  }
  .landing-package-cols-1-add-on-point {
    display: flex;
    gap: 5px;
    padding: 5px 0 5px 10px;
    text-align: start;
    font-size: 0.9rem;
  }
  .landing-package-cols-1-add-on-point i {
    color: #fe0000;
  }

  .landing-package-cols-1-size-rupee {
    font-size: 1rem;
  }
  .landing-package-cols-1-size-rupee i {
    font-size: 0.9rem;
  }

  .landing-package-cols-1-size-text {
    width: 95px;
  }

  .landing-package-testimonial {
    /* margin: 60px 0; */
  }


  
/* ------------------ boarding ----------------- */
.landing-boarding-cols{
  /* height: 500px; */
  padding: 20px;
  margin: 10px 10px;
  border-radius: 20px;
  box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;
}

.landing-boarding-cols{
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}
  
.landing-boarding-col-1{
  width: 70%;
  margin: auto;
}
.landing-boarding-col-1 img{
  width: 100%;
  margin: auto;
  /* padding: 0 80px; */
}

.landing-boarding-col-2-heading{
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.landing-boarding-col-2-points{
  padding: 0 15px;
  gap: 5px;
}
.landing-boarding-col-2-point{
  font-size: 1rem;
}
  
  /* ------------------ footer ----------------- */

  .landing-footer-col-1 {
    padding: 0;
    gap: 20px;
  }

  .landing-footer-cols {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px;
    gap: 0 50px;
  }

  .landing-footer-logo-icon img {
    width: 4rem;
  }

  .landing-footer-logo-text-1 {
    font-size: 1.5rem;
  }

  .landing-footer-logo-text-2 {
    font-size: 0.83rem;
  }

  .landing-footer-col-1-footer-icon > a > i {
    font-size: 1rem;
    padding: 8px;
  }

  .landing-footer-col-2 {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 40px 30px;
    gap: 50px 30px;
  }

  .landing-footer-col-2-col-heading {
    font-size: 1.2rem;
  }
  .landing-footer-col-2-col-points {
    font-size: 0.95rem;
  }

  .landing-footer-cc {
    font-size: 0.85rem;
  }
  
  
.landing-package-cols-1-heading-2 {
  font-size: 1.2rem;
}
  
.landing-package-cols-1-heading-2>span>span{
  font-size: 1.4rem;
}
  
/* --------------------------------------- */
.header-small{
  margin-top: -20px;
  display: block;
  position: relative;
}
.header-small-img{
  /* position: absolute; */
  display: flex;
}
.header-small-content{
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  padding: 35px 10px;
  height: 100%;
}

.header-small-img>img{
  width: 100%;
}

.header-small-content-heading-1{
  font-size: 1.5rem;
  font-weight: 1000;
  font-family: playpen sans;
}

.header-small-content-heading-2{
  font-size: 1.5rem;
  font-weight: 1000;
  font-family: playpen sans;
}
.header-small-content-heading-2 span{
color: #fe0000;
}
/* ---------------------------------------------- */


.header-numbers {
  padding: 20px 5px;
}

.header-numbers-col-inc {
  font-size: 1rem;
}

.header-numbers-col-inc span {
  font-size: 1.4rem;
}

.header-numbers-col-txt {
  font-size: 0.8rem;
  line-height: 1.4;
}

}




