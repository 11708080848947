.header {
  background-image: url(../../media/images/header1.jpg);
  background-position: center;
  background-size: cover;
  /* border-radius: 40px 40px 0 0; */
  /* height: 80vh; */
  height: auto;
  display: flex;
  flex-direction: column;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px; */
}

.header-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* padding: 70px 0 80px 0; */
  padding: 70px;
  gap: 20px;
  /* color: #e90101; */
}

.header-content-heading {
  font-family: "Times New Roman", Times, serif;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  text-shadow: rgb(255, 255, 255) 1px 0 4px;
}

.header-content-input {
  display: flex;
  align-items: center;
  background-color: white;
  /* padding: 5px; */
  width: 35rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

.header-content-input input {
  border-radius: 7px;
  border: none;
  width: 100%;
  font-size: 1.1rem;
  height: 100%;
  padding: 0 20px;
  background-color: transparent;
}

.header-content-input span {
  color: white;
  font-size: 1.3rem;
  height: 100%;
  background-color: #e90101;
  padding: 6px 15px;
  border-radius: 0 10px 10px 0;
}

.header-content-link {
  color: black;
  color: white;
  text-decoration: none;
  font-weight: 600;
  background-color: #e90101;
  padding: 5px 15px;
}

.header-content-link > span {
  color: white;
  background-color: black;
  border-radius: 50px;
  padding: 10px 15px;
  margin: 0 10px 0 0;
}

.header-menu-icon {
  margin: auto;
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  margin-top: -80px;
  font-size: 1.1rem;
  font-weight: 600;
}
.header-menu-icon > div > img {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  width: 100px;
  border-radius: 50%;
}
.header-menu-icon > div {
  display: grid;
  text-align: center;
}

.header > svg {
  fill: white;
  margin-bottom: -1px;
}

@media only screen and (max-width: 1000px) {
  .header {
    border-radius: 20px 20px 0 0;
    /* height: 60vh; */
    height: auto;
  }

  .header-large {
    display: none;
  }

  .header-content {
    padding: 25px 0 45px;
    gap: 10px;
  }

  .header-content-heading {
    font-family: "Times New Roman", Times, serif;
    font-size: 2rem;
    font-weight: 1000;
    /* font-weight: bolder; */
    text-align: center;
    text-shadow: rgb(255, 255, 255) 1px 0 3px;
  }

  .header-content-input {
    width: 90%;
    border-radius: 6px;
  }
  .header-content-input input {
    font-size: 1rem;
    padding: 2px 15px;
  }

  .header-content-input span {
    padding: 2px 10px;
    /* height: 100%; */
    border-radius: 0 6px 6px 0;
  }

  .header-content-link > span {
    /* border: solid 1px; */
    padding: 7px 10px;
    box-shadow: rgba(255, 255, 255, 0.618) 0px 2px 8px 0px;
  }

  .header-content-link {
    font-size: 0.8rem;
    padding: 3px 15px;
  }

  .header-menu-icon {
    margin-top: -35px;
    justify-content: space-between;
    width: 95%;
  }

  .header-menu-icon > div > img {
    width: 50px;
  }

  .header-menu-icon > div {
    font-size: 0.7rem;
  }

  /* ------------- main --------------- */

  .header > svg {
    display: none;
  }

  .header-content {
    display: none;
  }

  .header-menu-icon {
    display: none;
  }

  .header {
    background: none;
  }

  /* ----------  header  input ------------ */

  .header-sd-input {
    width: 90%;
    margin: auto;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 20px 0 10px;
  }

  .header-sd-input-inp {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: white;
    padding-right: 15px;
    border-radius: 15px;
    box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;
  }

  .header-sd-input input {
    width: 100%;
    border: none;
    /* font-size: 1.1rem; */
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 1rem;
  }
  .header-sd-filter {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .header-sd-filter img {
    width: 42px;
    background-color: #ce0000;
    padding: 7px 9px;
    border-radius: 10px;
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
      -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
      inset -4px -4px 6px 1px rgba(176, 176, 176, 0.281),
      inset 5px 4px 6px 0px rgba(29, 29, 29, 0.412);
  }

  /* ------------- header category -------------- */

  .header-category {
    display: flex;
    /* margin: 20px 0 30px 0px; */
    /* margin: 10px 0; */
    gap: 20px;
    /* margin-left: 20px; */
    overflow: auto;
    padding:  20px;
  }
  .header-category div div i {
    font-size: 1.2rem;
  }
  .header-category div div {
    color: #ff6e6e;
    padding: 0 8px ;
    font-size: 1.2rem;
  }

  .header-category div {
    background-color: rgb(255, 255, 255);
    padding: 8px 10px;
    font-size: 0.9rem;
    font-size: 1.1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 5px;
    /* justify-content: center; */
    text-align: center;
    border-radius: 15px;
    /* box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px; */
  }

  .header-category > div {
    box-shadow: rgba(170, 169, 169, 0.973) 0px 2px 8px 0px;
  }

  .header-category div img {
    border-radius: 50%;
    width: 50px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  }

  .n3-i1 {
    margin-left: 10px;
  }
}
