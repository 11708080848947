.offer-slider {
  padding: 50px 30px 30px;
}
.offer-slider-heading {
  font-family: "Times New Roman", Times, serif;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}

.offer-img {
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.offer-img img {
  width: 100%;
  border-radius:  50px 10px;
}

.offer-dots {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  text-align: center;
}

.offer-dots span {
  padding: 6px;
  border-radius: 100px;
  background-color: rgb(180, 180, 180);
}

.offer-dots :nth-child(1) {
  background: rgb(76, 76, 76);
}
.offer-slider-e{
  display: none;
}

@media only screen and (max-width: 1000px) {
  .offer-slider {
    padding: 10px 20px;
  }

  .offer-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .offer-img img {
    width: 100%;
  }

  .offer-slider-ld {
    display: none;
  }
  
.offer-slider-e{
  display: block;
  box-shadow: rgba(0, 0, 0, 0.574) 0px 2px 8px 0px;

}

  .offer-dots {
    margin-top: 10px;
    gap: 10px;
  }

  .offer-dots span {
    padding: 4px;
  }

.offer-img img {
  border-radius: 20px;
}

}
