.popup-offer{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.786);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-offer-main{
    box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;
    background-color: #f9cece;
    background-image: url("../../media/images/offer-bg-2.png");
    background-size: cover;
    background-position: right;
    border-radius: 20px;
}
.popup-offer-close{
    text-align: end;
    font-size: 2rem;
    margin-bottom: -10px;
    line-height: 1rem;
    color: #ce0000;
}
.popup-offer-close i{
    text-shadow: rgb(255, 255, 255) 1px 0 4px;
    border-radius: 50%;

}

.popup-offer-content{
    padding: 50px 30px;
    width: 600px;
}

.popup-offer-heading-1{
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 1rem;
    /* color: white;
    text-shadow: rgb(0, 0, 0) 1px 0 4px; */
}

.popup-offer-heading-2{
    text-shadow: rgb(192, 192, 192) 1px 0 4px;
    font-size: 3.5rem;
    font-weight: 700;
    color: #ce0000;
    
}

.popup-offer-heading-3{
    font-size: 1.5rem;
    font-weight: 500;
}

.popup-offer-heading-3>span{
background-color: rgba(255, 255, 255, 0.77);
padding: 0 10px;
border-radius: 5px;
box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;

}
.popup-offer-content>form{
    display: grid;
    width: 300px;
    gap: 10px;
    padding: 20px 0 10px;
}

.popup-offer-content>form input{
    padding: 8px;
    font-size: 1.1rem;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;

}
.popup-offer-content>form button{
    color: white;
    background-color: #ce0000;
    border: none;
    border-radius: 20px;
    box-shadow: 4px 4px 6px 0 rgba(255,255,255,.5), -4px -4px 6px 0 rgba(116, 125, 136, .5), inset -4px -4px 6px 0 rgba(255,255,255,.2), inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
    width: 200px;
    font-size: 1.3rem;
    font-weight: 600;
    padding: 8px;
}

/* ---------------------------- landing offer -------------------- */

.landing-offer{
    position: fixed;
    right: 10px;
    bottom: 10px;
    text-align: center;
    box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, .5), inset -4px -4px 6px 0 rgba(255,255,255,.2), inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
    padding: 10px;
    border-radius: 15px;
    background-image: url(../../media/images/offer-bg.jpg);
    background-size: cover;
    background-position: center;
    color: white;
    /* text-shadow: #000000 1px 0 1px; */
    font-family: poppins;
    z-index: 1;
}
.landing-offer-content{
    display: grid;
    gap: 10px;
    padding: 10px 10px;
}

.landing-offer-close{
    position: absolute;
    top: -20px;
    right: -10px;
    color: #d50202;
    font-size: 2rem;
    
}

.landing-offer-close i{
    background-color: white;
    border-radius: 50%;
}

.landing-offer-heading-1{
    font-size: 1.5rem;
    font-weight: 600;
    /* color: #ff0000; */
    background-color: rgba(255, 0, 0, 0.471);
    /* text-shadow: #ffffff -.3px .31px 0px; */
    border-radius: 5px;
    border: solid rgba(255, 255, 255, 0.599) 1px;
}


.landing-offer-heading-2{
    font-size: 1.3rem;
    font-weight: 600;
    
    /* text-shadow: #ff0000 -1px 1px 0px; */
}

.landing-offer-heading-3{
    margin: 5px;
}

.landing-offer-heading-3 span{
    font-size: 1rem;
    font-weight: 600;  
    background-color: #ce0000;
    border: none;
    border-radius: 20px;
    box-shadow: 4px 4px 6px 0 rgba(119, 119, 119, 0.5), -4px -4px 6px 0 rgba(80, 80, 80, 0.5), inset -4px -4px 6px 0 rgba(255,255,255,.2), inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
    padding: 1px 15px;
}

.landing-offer-heading-4{
    /* color: black; */
    font-weight: 500;
    font-size: 1.2rem;
}

.landing-offer-heading-5{
    font-size: 1.3rem;
    font-weight: 600;
}
.landing-offer-heading-5 a{
    color: red;
    background-color: rgba(255, 255, 255, 0.814);
}


.landing-offer-heading-0{
    font-size: 1.5rem;
    font-weight: 600;
}

@media only screen and (max-width: 640px) {

.popup-offer-main{
    width: 100%;
    margin: 10px;
}
    
    
.popup-offer-content{
    padding: 20px;
    width: 100%;
}

.popup-offer-heading-1{
    font-size: 1.7rem;
}
.popup-offer-heading-2{
    font-size: 2.5rem;
}
.popup-offer-heading-3{
    font-size: 1rem;
    
}

.popup-offer-content>form{
width: 280px;
gap: 5px;
}

.popup-offer-content>form input{
    padding: 5px 10px;
    font-size: 1rem;
}

.popup-offer-content>form button{
    font-size: 1.1rem;
    width: 150px;
    padding: 5px;
}

/* ---------------------------- landing offer -------------------- */

.landing-offer-content{
    display: grid;
    gap: 5px;
    padding: 5px 0px;
}

.landing-offer-heading-1{
    font-size: 1.3rem;
}

.landing-offer-heading-2{
    font-size: .9rem;
}

.landing-offer-heading-4{
    font-size: 1rem;
}

.landing-offer-heading-5{
    font-size: 1.1rem;
}
    

}







