.keywords>div{
    max-width: 1300px;
    margin: 80px auto;
}

.keywords-heading{
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 20px 0;
}
.keywords-heading>span{
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 500;
    width: 120px;
}

.keywords-heading span{
    color: #fe0000;
}

.keywords-content span{
    cursor: pointer;
    color: #fe0000;
    font-weight: 500;

}


@media only screen and (max-width: 800px) {
    .keywords>div{
        max-width: 1300px;
        margin: 40px 15px;
    }
    
    .keywords-heading{
        font-size: 1.4rem;
        flex-direction: column;
        margin: 10px 0;
    }
    .keywords-heading>span{
        font-size: 1rem;
        width: 100%;
    }
    
    .keywords-content{
        font-size: .9rem;
    }
    .keywords-content span{
        color: #fe0000;
        font-weight: 500;
    
    }
    
        
}