.groom-book-main{
    height: 100vh;
    width: 100%;
    background: rgba(74, 68, 68, 0.886);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: auto;
}

.groom-book {
    width: 100%;
    display: grid;
    justify-content: center;
    font-family: 'poppins';
    /* padding-top: 10px; */
    position: relative;
    z-index: 3;
  }
  
  .groom-book-border-img{
    text-align: end;
    margin-right: 80px;
    z-index: 2;
  }
  .groom-book-border-img>img{
    /* margin-top: -86px; */
    width: 220px;
    /* position: relative; */
  }
  
  .groom-book-form {
    /* position: absolute; */
      margin-top: -56px;
      /* margin: auto; */
    width: 950px;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px; */
    /* box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.5), inset -4px -4px 6px 1px rgba(255, 255, 255, 0.227), inset 5px 4px 6px 2px rgba(0, 0, 0, 0.412); */
    background: white;
    border-radius: 30px;
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
    -4px -4px 6px 0 rgba(116, 125, 136, .5), 
  inset -4px -4px 6px 0px rgba(255,255,255,.2),
  inset 4px 4px 6px 2px rgba(0, 0, 0, 0.244);
    display: flex;
    gap: 25px;
  }
  
  .groom-book-form-img{
    display: flex;
    width: 500px;
    background-image: url("../../media/images/form-img-0.jpeg");
    border-radius: 30px 0 0 30px;
    background-size: cover;
    background-position: center;
  }
  
  .groom-book-form-img img{
    /* height: 100px; */
  }
  
  .groom-book-form-content{
    width: 100%;
  }
  
  .groom-book-form-content-form{
    display: flex;
  }
  
  .groom-book-form-content-head{
    display: flex;
    justify-content: space-between;
  }
  
  
  .groom-book-form-content-head-heading-1{
    padding-top: 20px;
    font-size: 1.6rem;
    font-weight: 700;
  }
  .groom-book-form-content-head-heading-2{
    font-size: 1.8rem;
    font-weight: 1000;
    font-family: 'playpen sans';
    color: #fe0000;
    padding: 10px 0 20px;
  
  }
  
  .groom-book-form-content-head-col>button{
    background: none;
    border: none;
    font-size: 2rem;
    color: #fe0000;
    padding: 10px;
  }
  .groom-book-form-content-head-col>button>i{
    border-radius: 50%;
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
    -4px -4px 6px 0 rgba(116, 125, 136, .5), 
  inset -4px -4px 6px 0px rgba(255,255,255,.2),
  inset 4px 4px 6px 2px rgba(0, 0, 0, .4);
  }
  
  .groom-book-form-content-form form{
    display: grid;
    width: 100%;
    gap: 20px;
    padding-bottom: 20px;
    
  }
  .groom-book-form-content-form label{
    font-weight: 600;
  }
  .groom-book-form-content-form input{
    padding: 8px 8px;
    border: solid 2px rgba(114, 114, 114, 0.607);
    /* border: none;
    border-bottom: solid rgba(255, 0, 0, 0.404); */
    border-radius: 10px;
  }
  .groom-book-form-content-form select{
    padding: 8px;
    border: solid 2px rgba(114, 114, 114, 0.492);
    border-radius: 10px;
  width: 300px;
  }
  
  .groom-book-form-content-form textarea{
    padding: 8px;
    border: solid 2px rgba(114, 114, 114, 0.492);
    border-radius: 10px;
    font-family: 'poppins';
  /* width: 300px; */
  }
  
  .groom-book-form-content-form-col1{
    display: flex;
    gap: 80px;
    font-size: 1.1rem;
    width: 100%;
    align-items: center;
  
    /* font-weight: 600; */
  }
  
  .groom-book-form-content-form-col1-inp{ 
    display: flex;
    /* justify-content: space-between; */
    /* width: 100%; */
    gap: 100px;
  }
  
  .groom-book-form-content-form-col1-inp-1{
    display: flex;
    gap: 10px;
  }
  
  .groom-book-form-content-form-col1-inp-1 input{
    width: 20px;
  }
  
  
  
  .groom-book-form-content-form-col2{
    display: flex;
    gap: 30px;
    align-items: center;
  }
  
  .groom-book-form-content-form-col2-col{
    display: grid;
  }
  .groom-book-form-content-form-col2-col input{
    width: 250px;
  }
    
  .groom-book-form-content-form-col3{
    display: flex;
    gap: 35px;
    align-items: center;
  }
    
    
  .groom-book-form-content-form-col4{
    display: flex;
    gap: 30px;
    align-items: center;
  
  }
    
    
  .groom-book-form-content-form-col5{
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 90%;
  }
    
  .groom-book-form-content-form button{
    background-color: #fe0000;
    border: 0;
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
    padding: 8px;
    width: 200px;
    border-radius: 20px;
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
    -4px -4px 6px 0 rgba(116, 125, 136, .5), 
  inset -4px -4px 6px 0px rgba(255,255,255,.2),
  inset 4px 4px 6px 2px rgba(0, 0, 0, .4);
  }  
  
@media only screen and (max-width: 800px) {


    
    
  .groom-book-border-img{
    /* display: none; */
    margin-right: 50px;
  }
  .groom-book-border-img>img{
    width: 160px;
  }
  
    
  .groom-book{
    padding: 20px;
  }
    
  .groom-book-form {
      margin-top: -43px;
      /* margin-top: 0px; */
    width: 100%;
    /* padding: 20px; */

  }

  .groom-book-form-content-head-heading-2 {
    font-size: 1.6rem ;
    padding: 10px 30px 0px 20px ;
    
  }
   
  .groom-book-form-content-head-col>button{
    /* padding: 0; */
    font-size: 1.7rem;
  }
    
    .groom-book-form-img{
        display: none;
      }
      
      
  .groom-book-form-content-form form{
    display: grid;
    width: 100%;
    padding: 15px 20px;
    gap: 10px;
    
  }
    
  .groom-book-form-content-form-col1{
    gap: 30px;
    font-size: 1rem;
  }

  
  .groom-book-form-content-form-col2{
    display: grid;
    gap: 12px;
  }
  .groom-book-form-content-form-col3,
  .groom-book-form-content-form-col4{
      display: grid;
      grid-template-columns: 3fr 5fr;
      gap: 0px;
    }
    .groom-book-form-content-form-col5{
      display: grid;
      grid-template-columns: 1fr 4fr;
      gap: 10px;
        width: 100%;
        /* align-items: center; */
    }
  
  
  .groom-book-form-content-form-col1-inp{
    gap: 30px;
    font-size: .9rem;
  }
  
  .groom-book-form-content-form-col1-inp-1 input{
    width: 1rem;
  }

  .groom-book-form-content-form-col2-col{
    display: grid;
    grid-template-columns: 2fr 7fr;
    gap: 1px;
    align-items: center;
  }
  
  
  .groom-book-form-content-form-col2-col input{
    width: 100%;
  }
  .groom-book-form-content-form select,
  .groom-book-form-content-form textarea,
  .groom-book-form-content-form input{
    padding: 5px;

  }
  .groom-book-form-content-form input{
    padding: 8px;

  }

  .groom-book-form-content-form select{
    width: 100%;
  }
  
  .groom-book-form-content-form label{
    font-weight: 600;
    font-size: .9rem;
  }
      

  
  .groom-book-form-content-form button{
    background-color: #fe0000;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    padding: 8px;
    width: 150px;
    /* margin: 10px 0 0; */
  }  

      
      
}
  
  
  
  