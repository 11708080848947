.grooming-page {
  font-family: "poppins";
}

.grooming-page-header {
  margin-top: -8px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  background-color: white;
}
.grooming-page-content-col-1 {
  /* position: fixed; */
  position: sticky;
  width: 500px;
  top: 0px;
  height: 100vh;
  padding-top: 20px;
  /* z-index: -1; */
  /* position: absolute; */
}

.grooming-page-content-col-1-form-img {
  /* text-align: end; */
  margin-left: 50px;
}

.grooming-page-content-col-1-form-img img {
  width: 200px;
}

.grooming-page-content-col-1-form-form {
  margin: 20px;
  margin-top: -52px;
  border-radius: 20px;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 4px 4px 6px 0 rgba(224, 223, 223, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
    inset -4px -4px 6px 1px rgba(75, 75, 75, 0.227),
    inset 5px 4px 6px 2px rgba(30, 30, 30, 0.412);
}

.grooming-page-content-col-1-form-form-heading {
  font-size: 2.2rem;
  font-weight: 700;
  color: #fe0000;
  margin-left: 50px;
}

.grooming-page-content-col-1-form-form-col-1 {
  display: grid;
  grid-template-columns: 3fr 8fr;
  gap: 20px;
}

.grooming-page-content-col-1-form-form > form {
  display: grid;
  gap: 15px;
}

.grooming-page-content-col-1-form-form label {
  font-weight: 600;
}

.grooming-page-content-col-1-form-form > form input,
.grooming-page-content-col-1-form-form > form select,
.grooming-page-content-col-1-form-form > form textarea {
  padding: 5px 10px;
  border-radius: 10px;
  border: solid 2px rgba(114, 114, 114, 0.607);
  font-family: "poppins";
}

.grooming-page-content-col-1-form-form-col-1-inp {
  display: flex;
  gap: 100px;
}

.grooming-page-content-col-1-form-form-col-1-inp-1 {
  display: flex;
  gap: 10px;
}
.grooming-page-content-col-1-form-form-col-1-inp-1 > input {
  width: 1rem;
  font-size: 3rem;
}

.grooming-page-content-col-1-form-form-col-2 {
  display: grid;
  gap: 15px;
}

.grooming-page-content-col-1-form-form-col-2-col {
  display: grid;
  grid-template-columns: 3fr 8fr;
  gap: 20px;
}

.grooming-page-content-col-1-form-form-col-3 {
  display: grid;
  grid-template-columns: 3fr 8fr;
  gap: 20px;
}

.grooming-page-content-col-1-form-form-col-4 {
  display: grid;
  grid-template-columns: 3fr 8fr;
  gap: 20px;
}

.grooming-page-content-col-1-form-form-col-5 {
  display: grid;
  grid-template-columns: 3fr 8fr;
  gap: 20px;
}

.grooming-page-content-col-1-form-form-button > button {
  animation: wiggle 2s linear infinite;
  background: #fe0000;
  color: white;
  border: none;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 30px;
  width: 200px;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
    inset -4px -4px 6px 1px rgba(255, 255, 255, 0.227),
    inset 5px 4px 6px 2px rgba(0, 0, 0, 0.412);
}

.grooming-page-content-col-2 {
  margin: 30px 0 0 520px;
  margin-top: -95vh;
  /* text-align: end; */
  /* width: 500px; */
  /* height: 200vh; */
  /* z-index: -1; */
}

.grooming-page-content-col-2-header {
  padding: 10px 30px;
  margin-right: 30px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 4px 4px 6px 0 rgba(222, 219, 219, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
    inset -4px -4px 6px 1px rgba(130, 129, 129, 0.227),
    inset 5px 4px 6px 2px rgba(46, 46, 46, 0.412);
}
.grooming-page-content-col-2-header-heading-1 {
  /* font-family: "nunito"; */
  font-size: 2.5rem;
  font-weight: 800;
  font-weight: 700;
  color: #fe0000;
  /* margin-bottom: -10px; */
}

.grooming-page-content-col-2-header-heading-1>span>span>br{
  display: none;
}
.grooming-page-content-col-2-header-heading-1 span {
  color: black;
}
.grooming-page-content-col-2-header-heading-2 {
  font-family: "nunito";
  font-family: "playpen sans";
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  font-weight: 1000;
  /* padding-bottom: 10px; */
}

.grooming-page-content-col-2-header-heading-2 img {
  width: 4rem;
}

.grooming-page-content-col-2-header-heading-2 span {
  color: #fe0000;
}

.grooming-page-content-col-2-heading-3 {
  font-size: 1.3rem;
}

.grooming-page-content-col-2-heading-4 {
  font-size: 1.9rem;
  text-align: center;
  font-weight: 700;
  margin-top: 30px;
  background: linear-gradient(111.3deg, #f89b29 29.6%, #fe0000 93.6%);
  color: #ffffff;
  margin: 40px 40px 0px 20px;
  border-radius: 20px;
  /* ------------------ */
  /* padding: 10px 30px;
  margin-right: 30px;
  border-radius: 20px;
  box-shadow: 4px 4px 6px 0 rgba(222, 219, 219, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
    inset -4px -4px 6px 1px rgba(0, 0, 0, 0.227),
    inset 5px 4px 6px 2px rgba(0, 0, 0, 0.412); */
}

.grooming-page-content-col-2-heading-4 span{
  /* color: #fe0000; */
}


.grooming-page-content-col-2-categ{
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: center;
  align-items: center;
  padding: 20px 0;
}

.grooming-page-content-col-2-categ-text{
  font-size: 1.5rem;
  font-weight: 600;
}
.grooming-page-content-col-2-categ-text span{
  color: #fe0000;
}


.grooming-page-content-col-2-categ-img{
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  gap: 50px;
}

.grooming-page-content-col-2-categ-img-col{
  background-color: #ffffff;
  padding: 15px 20px 0 20px;
  border-radius: 20px;
  box-shadow: 4px 4px 6px 0 rgba(222, 219, 219, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
    inset -4px -4px 6px 1px rgba(0, 0, 0, 0.227),
    inset 5px 4px 6px 2px rgba(0, 0, 0, 0.412); 
}

.grooming-page-content-col-2-categ-img-col-heading{
  font-size: 1.2rem;
  font-weight: 600;
 
}


.grooming-page-content-col-2-categ-img-col-img img{
  width: 100px;
  display: flex;
}

.grooming-page-content-col-2-package-cols{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;
  padding-right: 20px;
}

.grooming-page-content-col-2-categ{
  display: none;
}





.grooming-page-footer {
  /* z-index: 1 !important; */

  /* position: relative; */
  /* z-index: 2; */
}

@media only screen and (max-width: 1400px) {

  
  /* ------------------- left --------------- */
  
  
.grooming-page-content-col-1 {
  padding-top: 20px;
}


.grooming-page-content-col-1-form-img img {
  width: 150px;
}

.grooming-page-content-col-1-form-form {
  margin-top: -40px;
}

.grooming-page-content-col-1-form-form-heading {
  font-size: 1.8rem;
}


.grooming-page-content-col-1-form-form > form input,
.grooming-page-content-col-1-form-form > form textarea {
  padding: 5px 10px;
  border-radius: 10px;
  border: solid 2px rgba(114, 114, 114, 0.607);
  font-family: "poppins";
}


.grooming-page-content-col-1-form-form-col-3 {
  display: grid;
  grid-template-columns: 3fr 6fr;
  gap: 20px;
}
  
.grooming-page-content-col-1-form-form-col-4{
  grid-template-columns: 3fr 5fr;
  gap: 0;
}
.grooming-page-content-col-1-form-form > form select{
  padding: 5px 0;
}
  

/* ------------ right --------------------- */
.grooming-page-content-col-2-header-heading-1 {
  font-size: 2rem;
}

.grooming-page-content-col-2-header-heading-2 {
  font-size: 2rem;
}
.grooming-page-content-col-1 {
  width: 420px;
}


.grooming-page-content-col-2 {
margin: 30px 0 0 420px;
margin-top: -95vh;

}

.grooming-page-content-col-2-heading-4{
  font-size: 1.7rem;
}

}


@media only screen and (max-width: 600px) {
.grooming-page-content-col-1 {
  display: none;
}



.grooming-page-content-col-2 {
  margin: 20px 10px;
  /* margin-top: 20px; */
}


.grooming-page-content-col-2-header {
  padding: 10px 10px;
  margin-right: 0px;
}


.grooming-page-content-col-2-header-heading-2{
  font-size: 1.4rem;
  text-align: center;
  justify-content: center;
}

.grooming-page-content-col-2-header-heading-2 img {
  width: 2.2rem;
}

.grooming-page-content-col-2-header-heading-1 {
  /* font-family: "nunito"; */
  font-size: 1.5rem;
  text-align: center;
  margin-top: 5px;

}
.grooming-page-content-col-2-header-heading-1>span>span>br{
  display: block;
}


.grooming-page-content-col-2-heading-4 {
  font-size: 1.3rem;
  padding: 10px;
  margin: 10px;
}

.grooming-page-content-col-2-package-cols{
  padding: 0;
}

#cat-groom{
  margin-top: 40px;
}

.grooming-page-content-col-2-categ{
  display: block;
}

.grooming-page-content-col-2-categ{
  grid-template-columns: 1fr;
  text-align: center;
}

.grooming-page-content-col-2-categ-text{
  font-size: 1.3rem;
}



.grooming-page-content-col-2-categ-img-col{
  background-color: #ffffff;
  padding: 15px 15px 0 15px;
  
  color: black;
  text-decoration: none;
}
.grooming-page-content-col-2-categ-img-col-heading{
  font-size: 1rem;
}
.grooming-page-content-col-2-categ-img-col-img img{
  width: 80px;
}

.grooming-page-content-col-2-categ-img{
  gap: 25px;
  justify-content: center;
}
.grooming-page-content-col-2-categ-button{
  background-color: #fe0000;
  width: 250px;
  margin: auto;
  margin-top: 10px;
  font-size: 1.3rem;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  box-shadow: 4px 4px 6px 0 rgba(255,255,255,.5), -4px -4px 6px 0 rgba(116, 125, 136, .5), inset -4px -4px 6px 0px rgba(255,255,255,.2), inset 4px 4px 6px 2px rgba(0, 0, 0, .4);
  animation: wiggle 2s linear infinite;

}







}












@media only screen and (max-width: 400px) {
  

.grooming-page-content-col-2-header-heading-2{
  font-size: 1.2rem;
}

.grooming-page-content-col-2-header-heading-1{
  font-size: 1.4rem;
}

}








