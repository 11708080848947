/* .navbar {
  display: flex;
  justify-content: space-between;
  padding: 0px 60px;
  align-items: center;
  margin-top: 10px;
}

.navbar a {
  color: rgb(47, 47, 47);
  color: black;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-weight: 500;
}

.nav-logo img {
  width: 4rem;
}

.nav-logo {
  color: rgb(0, 0, 0);
  font-family: playpen sans;
  font-weight: 800;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
}

.nav-logo span{
  color: #e9653c;
  color: #ce0000;
  text-shadow: rgba(255, 255, 255, 0.27) 1px 2px 4px;
}

.navbar-menu{
  gap: 2rem;
  display: flex; 
}

.nav-menu {
  display: flex;
  gap: 2rem;
}

.nav-menu .nav-icon {
  font-size: 1.1rem;
}
.nav-btn {
  display: none;
}
.nav-end-icon{
  display: none;
}

@media only screen and (max-width: 1000px) {
  .navbar{
    margin: 0;
    padding: 5px 20px;
  }

  .nav-btn {
    color: white;
  }

  .menu-btn {
    display: block;
    margin-right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    background-color: white;
    box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;
    
    border-radius: 50%;
    padding: 4px 7px;
    color: black;
    margin: 0;
  }

  .nav-close-btn {
    display: block;
    position: absolute;
    top: 30px;
    left: 20px;
    font-size: 1.7rem;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .navbar .nav-menu {
    margin: 0px 0px;
    padding: 0;
    position: fixed;
    bottom: 100vh;
    left: 0rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 250px;
    justify-content: center;

  }

  .navbar .responsive_nav {
    transform: translateX(100vh);
    z-index: 2;
  }

  .nav-logo img{
    width: 45px ;
    padding: 0;
    
  }
  
  .nav-logo{
    font-size: 1.2rem;
    color: black;
  }

  .nav-end-icon i{
    color: black;
    font-size: 1.2rem;
  }
  
} */



.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 80px;
  font-family: "poppins";
}
.nav-header a{
  color: black;
  text-decoration: none;
  font-weight: 500;
}

.navbar-blank{
  display: none;
}
.nav-btn {
display: none;
}

.header a {
text-decoration: none;
/* cursor: grab; */
color: black;
}
.navbar-logo img {
width: 4rem;
}

.navbar-logo {
  color: rgb(0, 0, 0);
  font-family: playpen sans;
  font-weight: 800;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
}
.navbar-logo div{
  font-weight: 800;

}

.navbar-logo span {
  color: #e9653c;
  color: #ce0000;
  text-shadow: rgba(255, 255, 255, 0.27) 1px 2px 4px;
}

.navbar-menu {
display: flex;
gap: 40px;
}

.navbar-menu a i{
  font-size: 1.2rem;
}

@media only screen and (max-width: 1000px) {
  .nav-header {
      flex-direction: row-reverse;
      padding: 10px;
  }

  .navbar-blank{
      display: block;
      padding: 8px;
  }
  
.nav-menu-btn {
  display: block;
  margin-right: 10px;
  border: none;
  font-size: 1.2rem;
  border-radius: 50px;
  padding: 5px 8px;    
  box-shadow: 0 2px 8px 0 hsla(0,0%,51%,.973);
  color: black;
}

.nav-btn{
  color: #ce0000;

}

.navbar-logo img{
  width: 45px ;
  padding: 0;
  
}

.navbar-logo{
  font-size: 1.2rem;
  color: black;
}

.nav-header nav {
  position: fixed;
  bottom: 100vh;
  height: 100%;
  width: 100%;
  transition: 1s;
  gap: 0px;
  background-color: #ffecec;
}
nav>div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.nav-header .responsive_nav {
  transform: translateY(100vh);
  z-index: 2;
}

.nav-close-btn {
  display: block;
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 2rem;
}
}



